import cn from "classnames";
import React from "react";
import { Image } from "../../Image";
import "./styles.css";

const CeramicTileHolderHeroBackground = ({ className, background, alt }) => {
  return (
    <div className={cn(className, "CeramicTileHolderHeroBackground")}>
      <Image className="CeramicTileHolderHeroBackground__image" image={background} alt={alt} />
    </div>
  );
};

export { CeramicTileHolderHeroBackground };
