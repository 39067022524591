import { graphql } from "gatsby";
import React from "react";
import { CeramicTileHolderHeroBackground } from "./CeramicTileHolderHeroBackground";
import { CeramicTileHolderHeroContent } from "./CeramicTileHolderHeroContent";
import "./styles.css";

const CeramicTileHolderHero = ({ background, alt, subtitle, title, text, buttonLabel, onOpenContactUsModal }) => {
  return (
    <div className="[ CeramicTileHolderHero ] [ Content sm:Content_medium ]">
      <CeramicTileHolderHeroContent
        className="CeramicTileHolderHero__content"
        subtitle={subtitle}
        title={title}
        text={text}
        buttonLabel={buttonLabel}
        onOpenContactUsModal={onOpenContactUsModal}
      />

      <CeramicTileHolderHeroBackground
        className="CeramicTileHolderHero__background"
        background={background}
        alt={alt}
      />
    </div>
  );
};

CeramicTileHolderHero.getLivePreviewData = ({ data, getAsset }) => ({
  ceramicTileHolderHero: {
    ...data.ceramicTileHolderHero,
    background: getAsset(data.ceramicTileHolderHero.background).url,
  },
});

const ceramicTileHolderHeroQuery = graphql`
  fragment CeramicTileHolderHeroFragment on MarkdownRemarkFrontmatter {
    ceramicTileHolderHero {
      background {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      alt
      subtitle
      title
      text
      buttonLabel
    }
  }
`;

export { CeramicTileHolderHero, ceramicTileHolderHeroQuery };
