import { graphql } from "gatsby";
import React from "react";
import { CompletedProjects } from "../CompletedProjects/CompletedProjects";
import "./styles.css";

const CeramicTileHolderExamples = ({ title, examples }) => {
  return <CompletedProjects title={title} examples={examples} />;
};

CeramicTileHolderExamples.getLivePreviewData = ({ data, getAsset }) => ({
  ceramicTileHolderExamples: {
    ...data.ceramicTileHolderExamples,
    examples: data.ceramicTileHolderExamples.examples.map(example => ({
      ...example,
      photo: getAsset(example.photo).url,
    })),
  },
});

const ceramicTileHolderExamplesQuery = graphql`
  fragment CeramicTileHolderExamplesFragment on MarkdownRemarkFrontmatter {
    ceramicTileHolderExamples {
      title
      examples {
        photo {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
        description
      }
    }
  }
`;

export { CeramicTileHolderExamples, ceramicTileHolderExamplesQuery };
