import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const CeramicTileHolderAdvantages = ({ image, alt, title, text, load: { text: loadText, value, unit } }) => {
  return (
    <div className="[ CeramicTitleHolderAdvantages__container ] [ Content sm:Content_medium ]">
      <div className="CeramicTitleHolderAdvantages__image">
        <Image image={image} />
      </div>

      <div className="CeramicTitleHolderAdvantages__description">
        <div className="Font_header sm:Font_smallHeader">{title}</div>
        <div className="[ CeramicTitleHolderAdvantages__title ] [ Font_bigText sm:Font_regularText ]">{text}</div>
        <div className="CeramicTitleHolderAdvantages__subtitle">
          <div className="[ CeramicTitleHolderAdvantages__loadText ] [ Font_mediumText Color_middleGrey ]">
            {loadText}
          </div>
          <div className="CeramicTitleHolderAdvantages__value">{value}</div>
          <div className="CeramicTitleHolderAdvantages__unit">{unit}</div>
        </div>
      </div>
    </div>
  );
};

CeramicTileHolderAdvantages.getLivePreviewData = ({ data, getAsset }) => ({
  ceramicTileHolderAdvantages: {
    ...data.ceramicTileHolderAdvantages,
    image: getAsset(data.ceramicTileHolderAdvantages.image).url,
  },
});

const ceramicTileHolderAdvantagesQuery = graphql`
  fragment CeramicTileHolderAdvantagesFragment on MarkdownRemarkFrontmatter {
    ceramicTileHolderAdvantages {
      image {
        childImageSharp {
          fluid(maxWidth: 998) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      title
      text
      load {
        text
        value
        unit
      }
    }
  }
`;

export { CeramicTileHolderAdvantages, ceramicTileHolderAdvantagesQuery };
