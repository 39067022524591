import { graphql } from "gatsby";
import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import { CeramicTileHolderAdvantages } from "../components/CeramicTileHolderAdvantages/CeramicTileHolderAdvantages";
import { CeramicTileHolderExamples } from "../components/CeramicTileHolderExamples";
import { CeramicTileHolderHero } from "../components/CeramicTileHolderHero";
import { ContactUsModal } from "../components/ContactUsModal";
import { Layout } from "../components/Layout";

const CeramicTileHolderTemplate = ({ layout, ...props }) => {
  const [isOpenContactUsModal, setIsOpenContactUsModal] = useState();
  const handleOpenContactUsModal = useCallback(() => setIsOpenContactUsModal(true), []);
  const handleCloseContactUsModal = useCallback(() => setIsOpenContactUsModal(false), []);
  return (
    <Layout {...layout} onOpenContactUsModal={handleOpenContactUsModal} lang={props.layoutId}>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <CeramicTileHolderHero {...props.ceramicTileHolderHero} onOpenContactUsModal={handleOpenContactUsModal} />
      <CeramicTileHolderAdvantages {...props.ceramicTileHolderAdvantages} />
      <CeramicTileHolderExamples {...props.ceramicTileHolderExamples} />

      <ContactUsModal isOpen={isOpenContactUsModal} onClose={handleCloseContactUsModal} {...layout.contactUsModal} />
    </Layout>
  );
};

CeramicTileHolderTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...CeramicTileHolderHero.getLivePreviewData(props),
  ...CeramicTileHolderAdvantages.getLivePreviewData(props),
  ...CeramicTileHolderExamples.getLivePreviewData(props),
});

const CeramicTileHolderPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return <CeramicTileHolderTemplate layout={data.layout.frontmatter} {...frontmatter} />;
};

const pageQuery = graphql`
  query CeramicTileHolderTemplate($id: String!, $layoutId: String!) {
    layout: markdownRemark(frontmatter: { id: { eq: $layoutId } }) {
      frontmatter {
        ...LayoutFragment
        ...ContactUsModalFragment
      }
    }

    markdownRemark(id: { eq: $id }) {
      frontmatter {
        layoutId
        seo {
          title
          description
          keywords
        }

        ...CeramicTileHolderHeroFragment
        ...CeramicTileHolderAdvantagesFragment
        ...CeramicTileHolderExamplesFragment
      }
    }
  }
`;

export default CeramicTileHolderPage;

export { pageQuery, CeramicTileHolderTemplate };
